@import "../../index.scss";

.post {
  &__header {
    @include flex-row-center;
    position: relative;
  }

  &__infos {
    display: flex;
    gap: 12px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  &__image {
    border-radius: 15px;
    width: 100%;
  }

  &__footer {
    display: flex;
    gap: 24px;
    color: $color-grey-2;
    user-select: none;
  }

  &__likes {
    @include flex-row-center;
    gap: 12px;
    font-weight: 600;

    &:hover {
      cursor: pointer;
    }

    &--liked {
      color: #fd2d01;
    }
  }

  &__comments {
    @include flex-row-center;
    gap: 12px;
    font-weight: 600;
  }
}

.infos {
  &__group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;

    &--comment {
      gap: 2px;
    }
  }

  &__name {
    font-weight: 600;
    @include flex-row-center;
    gap: 4px;
  }

  &__date {
    font-size: 14px;
    color: $color-grey-2;
  }

  &__admin {
    display: flex;
    flex-direction: row;
    gap: 6px;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.comments-container {
  @include flex-column-skretch;
  gap: 12px;
}

.comment {
  &__infos {
    display: flex;
    gap: 12px;
  }

  &__content {
    font-size: 16px;
  }

  &__write-comment {
    display: flex;
    gap: 16px;
  }

  &__group {
    width: 100%;
    display: flex;
    gap: 12px;
  }
}