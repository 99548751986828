@import "../index.scss";

.profile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: 0px 0px 12px rgb(0 0 0 / 3%);
  overflow: auto;

  @media screen and (min-width: 740px) {
    border-radius: 20px;
  }

  &__banner {
    background-color: $color-secondary;
    height: 140px;

    @media screen and (min-width: 740px) {
      height: 180px;
    }
  }

  &__user {
    background-color: $color-light-1;
    padding: 24px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__infos {
    @include flex-row-center;
    gap: 16px;
  }

  &__avatar {
    outline: 4px solid $color-light-1;
    width: 80px;
    height: 80px;
    margin-top: -40px;

    @media screen and (min-width: 740px) {
      width: 120px;
      height: 120px;
      margin-top: -80px;
    }
  }

  &__name {
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    @include flex-row-center;
    gap: 4px;
  }

  &__job {
    color: $color-grey-2;
  }

  &__buttons-group {
    display: flex;
    gap: 12px;
  }

  &__change-avatar {
    @include flex-row-center;
    position: relative;
  }
}