@import "../../index.scss";

.avatar {
  border-radius: 100px;

  &-small {
    width: 40px;
    height: 40px;
  }

  &-large {
    width: 100px;
    height: 100px;
  }
}