@import "../../index.scss";

.nav {
  background-color: $color-light-1;
  height: 70px;
  @include flex-row-center;
  justify-content: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.025);

  &__container {
    width: 100%;
    padding: 0 20px;
    @include flex-row-center;
    justify-content: space-between;

    @media screen and (min-width: 780px) {
      max-width: 740px;
      margin: auto;
      padding: 0;
    }

    & a {
      line-height: 12px;
    }
  }

  &__logo {
    background: no-repeat url("../../assets/groupomania_icon.png");
    width: 39px;
    height: 40px;

    @media screen and (min-width: 540px) {
      background: no-repeat url("../../assets/groupomania_logo.png");
      width: 242px;
      height: 40px;
    }
  }

  &__profile {
    display: flex;
    gap: 12px;
  }
}