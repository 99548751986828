@import "../../index.scss";

.input {
  &-group {
    width: 100%;
    @include flex-row-center;
    position: relative;
    gap: 12px;
  }

  &-text {
    @include flex-row-center;
    padding: 12px 24px;
    width: 100%;
    height: 40px;
    background: $color-light-3;
    color: $color-black;
    border-radius: 20px;
    border: none;
    outline: none;
    line-height: 16px;

    &:focus {
      box-shadow: inset 0 0 0 1px $color-grey-1;
    }
  }

  &-textarea {
    overflow: hidden;
    resize: none;
    line-height: 18px;
  }

  &-file {
    @include flex-center;
    position: absolute;
    top: 5px;
    right: 5px;
    height: 30px;
    width: 30px;
    border-radius: 20px;
    cursor: pointer;

    &:hover {
      background-color: #dbdada;
    }
  }

  &-image_preview {
    display: flex;
    position: relative;
    max-width: max-content;

    & img {
      border-radius: 15px;
      width: 100%;
    }
  }
}

::placeholder {
  color: $color-grey-2;
}

input[type="file"] {
  display: none;
}